.tr_div {
  background-color: var(--tablehead);
  height: 65px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  border-bottom: 0px;
  color: var(--tableText);
  vertical-align: middle !important;
}
.table_style {
  background-color: var(--white);
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 0px !important;
}
.table_body_style {
  background-color: var(--white);
  border-collapse: seperate;
  border-spacing: 10px 15px;
  table-layout: fixed;
  width: 60%;
}
.table_body_div {
  margin: 20px;
  border-spacing: 0 5px;
  border-collapse: separate;
}
.tr_body_div {
  margin-bottom: 10px;
  height: 60px;
  color: var(--button);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding: 0 10px;
  line-height: 23px;
}
.tr_body_div td {
  border-width: 1px 0;
  border-color: var(--tablehead);
  border-style: solid;
  vertical-align: middle !important;
  word-break: break-word;
  /* padding: 0 20px; */
}
.tr_body_div td:first-child {
  border-left: 1px solid var(--tablehead);
  margin-left: 5px;
}
.tr_body_div td:last-child {
  border-right: 1px solid var(--tablehead);
}
.table table_style thead {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0px;
}
.table1 {
  caption-side: bottom;
  border-collapse: separate;
  width: 100%;
  border-spacing: 0px 8px;
  padding: 15px;
}
.table > :not(caption) > * > * {
  padding: 1rem 2rem !important;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.dark_table_div {
  color: var(--primary);
  text-align: center;
}
/* .innertablediv{
    width: 100%;
    padding: 10px;
} */
