body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Gilroy-Black';
  src: local('Gilroy-Black'), url('./assets/fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: bold;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Gilroy-Black monospace;
}
