@import url('fonts.scss');

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
    font-family: 'Caveat', sans-serif, monospace;
    font-weight: 700;
  }

  body {
    margin: 0;
    padding: 0;

    #root {
      overflow: auto;
    }

    button,
    input,
    select,
    textarea {
      border: 0;
      margin: 0;
      padding: 0;
      box-shadow: none;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  .modal-backdrop.show {
    opacity: 0.8;
  }
}