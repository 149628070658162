@font-face {
  font-family: 'Caveat';
  src: url(../assets/fonts/Caveat/Caveat-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Caveat';
  src: url(../assets/fonts/Caveat/Caveat-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Caveat';
  src: url(../assets/fonts/Caveat/Caveat-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Caveat';
  src: url(../assets/fonts/Caveat/Caveat-SemiBold.ttf);
  font-weight: 800;
}
